<template>
    <v-card class="elevation-12">
        <v-toolbar dark color="red">
            <v-toolbar-title> {{ $t('ForgotPassword') }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-form v-model="valid" ref="forgot-password-form" lazy-validation>
                <v-text-field
                    v-model="email"
                    prepend-icon="mdi-account"
                    name="email"
                    :label="$t('Email')"
                    type="email"
                    color="dark"
                    :rules="[filterRules.email, filterRules.required]"
                ></v-text-field>
                <div class="forgot-password-actions">
                    <v-btn 
                        :loading="isSendingLink" 
                        class="submit-btn"
                        dark 
                        color="dark"
                        @click="attemptSubmit"
                    > 
                        {{ $t('Submit') }} 
                    </v-btn>
                    <v-btn 
                        class="submit-btn"
                        dark 
                        color="darken-3"
                        @click="goTo('LoginPage')"
                    > 
                        {{ $t('Cancel') }} 
                    </v-btn>
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>

import Vue from 'vue';

export default {
    name: "ForgotPasswordForm",
    data() {
        return {
            valid: false,
            isSendingLink: false,
            email: null
        }
    },
    computed: {
        filterRules() {
            return Vue.formHelper.filterRules();
        }
    },
    methods: {
        attemptSubmit() {

            // to validate if none of the inputs were changed
            let validation = this.$refs['forgot-password-form'].validate();

            if (this.valid && validation) {

                this.$emit('link-sending', true);
                this.isSendingLink = true;
                
                this.$store.dispatch("auth/forgotPassword", {email: this.email})
                .then((success) => {
                    this.$emit('link-sent', true)
                })
                .catch((failure) => {
                    if (failure.response) {
                        let message = '';
                        if (failure.response.data.error) {
                            message = failure.response.data.error.message;
                        } else {
                            message = failure.response.data.message;
                        }
                        this.$root.$emit("notify-error", this.$t(message));
                    }
                    this.$emit('link-not-sent', true);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isSendingLink = false;
                    }, 500);
                });
            }

        },
        goTo(routeName) {
            var searchObj = Vue.urlHelper.extractQuery();
            if (searchObj["company_id"]) {
                this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
            } else {
                this.$router.push({name: routeName});
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.submit-btn {
    margin: 0px 10px;
    padding: 5px 20px;
}
.login-link {
    text-decoration: none;
    font-weight: bold;
    @media (max-width: 767px) {
        display: block;
        margin: 10px auto;
    }
}
.forgot-password-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media(max-width: 767px) {
        display: block;
        text-align: center;
    }
}
</style>