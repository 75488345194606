<template>
  <div>
    <polygon-background
      :styles="{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }"
      :animateHexagons.sync="animatePolygons"
    />
    <v-container fluid class="forgot-pw-page" fill-height>
      <v-row no-gutters justify="center" align="center">
        <v-col cols="12" md="6">
          <forgot-password-form v-show="!linkSent" @link-sent="linkSentSuccess" />
          <v-alert
            prominent
            type="success"
            v-show="linkSent"
            class=" mb-7 rounded-0 darken-1"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("PasswordResetLinkSent") }}
              </v-col>
              <v-col v-show="false" class="shrink">
                <v-btn @click="linkSent = false">
                    {{ $t('SendNewLink') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ForgotPasswordForm from "../../components/Form/Auth/ForgotPassword";
import PolygonBackground from "../../components/Util/PolygonBackground";

export default {
  name: "ForgotPasswordPage",
  components: {
    ForgotPasswordForm,
    PolygonBackground,
  },
  data() {
    return {
      animatePolygons: false,
      linkSent: false,
    };
  },
  methods: {
    linkSentSuccess() {
      this.linkSent = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.forgot-pw-page {
  height: 80vh;
}
</style>
